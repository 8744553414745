import { Typography } from "@material-ui/core";
import clsx from "clsx";
import CardSection from "components/styledComponents/CardSection";
import { createUseStyles } from "react-jss";
import ReactMarkdown from "react-markdown";

const useStyles = createUseStyles({
    containerClassName: {
        flexDirection: "row-reverse",
    },
    rightSectionClassName: {
        justifyContent: "flex-start",
    },
    image: {
        "& img": {
            height: "350px",
            width: "auto",
        },
    },
    flexColReverse: {},
    noMargin: {},
    centerSM: {},
    "@media screen and (max-width:760px)": {
        containerClassName: {
            flexDirection: "column-reverse",
        },
        flexColReverse: {
            flexDirection: "column-reverse",
        },
        rightSectionClassName: {
            marginTop: 0,
            justifyContent: "center",
        },
        noMargin: {
            marginTop: 0,
            justifyContent: "center",
        },
        image: {
            "& img": {
                height: "auto",
                marginBottom: "20px",
            },
        },
        centerSM: {
            textAlign: "center",
        }
    },
})

function AlternateCardSections({
    data,
    imageClassName = null,
    centerTitles = false,
    centerSMTitles = false,
    backgroundColor = ""
}) {
    const classes = useStyles();

    return (
        <div className="mtb20">
            {
                data.map((item, index: number) => (
                    <CardSection
                        backgroundColor={backgroundColor}
                        key={index}
                        slant={false}
                        containerClassName={index % 2 ? classes.containerClassName : classes.flexColReverse}
                        leftSectionClassName=""
                        rightSectionClassName={index % 2 ? classes.rightSectionClassName : classes.noMargin}
                        leftWidth={45}
                        leftSection={
                            <>
                                <Typography
                                    variant="h3"
                                    className={clsx(centerTitles ? "center" : "", centerSMTitles ? classes.centerSM : "")}
                                >
                                    {item.title}
                                </Typography>
                                <ReactMarkdown
                                    source={item.description}
                                />
                            </>
                        }
                        rightSection={
                            <span className={clsx(classes.image, imageClassName)}>
                                <img
                                    src={item.image}
                                    alt="StayQrious"
                                />
                            </span>
                        }
                    />
                ))
            }
        </div>
    )
}

export default AlternateCardSections;
