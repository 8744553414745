import { Typography } from '@material-ui/core';
import Button from 'components/generics/Button';
import CardSection from 'components/styledComponents/CardSection';
import { createUseStyles } from 'react-jss';
import ReactMarkdown from 'react-markdown';
import { Stream } from '@cloudflare/stream-react';
import Underline from 'components/generics/Underline';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import YouTubePlayer from 'components/generics/YoutubePlayer';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  description: {
    marginTop: '10px',
    '& strong': {
      fontWeight: 500
    }
  },
  videoSection: {
    display: 'block',
    margin: 0,
    marginBottom: '-100px'
  },
  footNoteSection: {
    marginTop: '40px'
  },
  video: {
    // margin: "0 auto",
    // height: "320px",
    // width: "580px",
    // paddingTop: "0!important",
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    // paddingTop: "56.25%",
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      // height: "320px!important",
      // width: "565px!important",
      border: 'none',
      borderRadius: '10px'
    }
  },
  underline: {},
  ytIframePlayer: {
    borderRadius: '10px'
  },
  '@media screen and (max-width:760px)': {
    container: {
      textAlign: 'center'
    },
    description: {
      '& strong': {
        fontWeight: 'normal'
      }
    },
    videoSection: {
      margin: 0,
      marginTop: '20px'
    },
    footNoteSection: {
      width: '85%',
      margin: '0 auto',
      textAlign: 'center'
    },
    video: {
      // height: "220px",
      // width: "400px",
      // "& iframe": {
      //     margin: "0 auto",
      //     height: "220px!important",
      //     width: "400px!important",
      // }
    },
    underline: {
      margin: '0 auto'
    }
  }
});

function Header({ data, handleSetApplyNowOpen }) {
  const classes = useStyles();

  const renderUnderLineTitle = (title: string, type: string) => {
    let titles = title.split(' ');

    return (
      <Typography variant="h1">
        {titles[0]}&nbsp;
        <Underline type={type}>{titles.slice(1).join(' ')}</Underline>
      </Typography>
    );
  };

  return (
    <CardSection
      leftWidth={40}
      rightSectionClassName={classes.videoSection}
      leftSection={
        <div className={classes.container}>
          <div>
            {renderUnderLineTitle(data.title1, 'primary')}
            {renderUnderLineTitle(data.title2, 'secondary')}
          </div>

          <ReactMarkdown
            className={classes.description}
            source={data.description}
          />
        </div>
      }
      rightSection={
        <>
          {data.youtubeID && (
            <div className={classes.video}>
              <YouTubePlayer youtubeID={data.youtubeID} />
            </div>
          )}
        </>
      }
      thirdSection={
        <div className={classes.footNoteSection}>
          <Typography className="mtb10">{data.subtitle}</Typography>
          <Button
            id="home-page-apply-now-header"
            variant="contained"
            color="secondary"
            onClick={(e) => handleSetApplyNowOpen(e)}
          >
            {data.cta_text}
          </Button>
          <ReactMarkdown
            source={data.footnote}
            className={classes.description}
          />
        </div>
      }
    />
  );
}

export default Header;
