import { Typography } from "@material-ui/core";
import clsx from "clsx";
import SlickSlider from "components/generics/SlickSlider";
import TestimonialCard from "components/generics/TestimonialCard";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    container: {
        padding: "50px 0",
    },
    title: {
        margin: '20px 0!important',
    },
    '@media screen and (max-width: 760px)': {
        container: {
            margin: "0",
            padding: "20px 0",
            paddingTop: "0",
        },
        title: {
            fontSize: '24px!important',
            margin: "10px 0!important"
        }
    }
})

function Testimonials({
    testimonials,
    CardComponent = TestimonialCard,
    slidesToShow = 3,
    backgroundColor = "#fff",
    autoplay = true
}) {
    const classes = useStyles();
    
    return (
        <div
            style={{
                background: backgroundColor
            }}
        >
            <div
                className="max-width"
            >
                <div className={clsx("sectionGap", classes.container)}>
                    <Typography
                        variant="h2"
                        className={clsx("center", classes.title)}
                    >
                        {testimonials.title}
                    </Typography>
                    <SlickSlider
                        data={testimonials.testimonials_list}
                        CardComponent={CardComponent}
                        slidesToShow={slidesToShow}
                        autoplay={autoplay}
                    />
                </div>
            </div>
        </div>
    )
}

export default Testimonials;
