import clsx from "clsx";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    strike: {
        display: "inline-block",
        margin: 0,
        "&::after": {
            top: "85%",
        }
    },
    "@media screen and (max-width:760px)": {
        strike: {
            "&::after": {
                top: "85%",
                height: "10px",
            }
        },
    }
})

function Underline({
    type = "primary",
    children,
    containerClassName = {}
}: {
    type?: string,
    children: React.ReactNode,
    containerClassName?: any,
}) {
    const classes = useStyles();
    const getTypeClassName = () => {
        if (type === "primary") {
            return "underline-primary"
        } else {
            return "underline-secondary"
        }
    }


    return (
        <div className={clsx(getTypeClassName(), "width-max-content", classes.strike, containerClassName)}>
            {children}
        </div>
    )
}

export default Underline;