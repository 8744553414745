import { Typography } from "@material-ui/core";
import clsx from "clsx";
import BlockBox from "components/generics/BlockBox";
import ExploreButton from "components/generics/ExploreButton";
import Underline from "components/generics/Underline";
import CardSection from "components/styledComponents/CardSection";
import { createUseStyles } from "react-jss";
import ReactMarkdown from "react-markdown";

const useStyles = createUseStyles({
    section: {
        padding: "0 10px",
    },
    container: {
        alignItems: "flex-start",
    },
    thirdSection: {
        marginTop: "-200px"
    },
    strike: {
        "&::after": {
            top: "35%",
        }
    },
    "@media screen and (max-width: 760px)": {
        section: {
            padding: '0 10px',
        },
        thirdSection: {
            marginTop: "0"
        },
        strike: {
            "&::after": {
                top: "20%",
            }
        },
    }
})

function TeachersSection({ data }) {
    const classes = useStyles();

    const renderTitle = (title: string) => {
        let titleArr = title.split(' ');
        let struck = titleArr.pop();

        return (
            <span>
                {titleArr.join(" ")} <span className={clsx("strike-primary", classes.strike)}>{struck}</span>
            </span>
        )

    }

    return (
        <BlockBox
            backgroundColor="#fff"
        >
            <CardSection
                leftWidth="45%"
                containerClassName={classes.container}
                leftSection={
                    <>
                        {/* temporary solution */}
                        <Typography
                            variant="h1"
                        >
                            {renderTitle(data.title1)}
                        </Typography>
                        <Underline>
                            <Typography
                                variant="h1"
                            >
                                {data.title2}
                            </Typography>
                        </Underline>
                    </>
                }
                rightSection={
                    <>
                        <img
                            src={data.image}
                            alt={"SQ"}
                        />
                    </>
                }
                thirdSection={
                    <div className={classes.thirdSection}>
                        <ReactMarkdown
                            source={data.description}
                        />
                    </div>
                }
            />
            <div className={classes.section}>
                <ExploreButton
                    text={data.cta_text}
                    url={data.cta_url}
                />
            </div>
        </BlockBox>
    )
}

export default TeachersSection;
