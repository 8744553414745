import { Typography } from "@material-ui/core";
import BlockBox from "components/generics/BlockBox";
import { createUseStyles } from "react-jss";
import ReactMarkdown from "react-markdown";

const useStyles = createUseStyles({
    investorList: {
        background: "#F2F9FF",
        borderRadius: 15,
        width: "80%",
        margin: "0 auto",
        display: "flex",
        flexWrap: "wrap",
        padding: "20px 40px",
    },
    investorItem: {
        display: "flex",
        padding: "20px 0",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto",
        width: "30%",
        "& img": {
            width: "100%",
            // width: "240px",
        }
    },
    "@media screen and (max-width: 768px)": {
        investorList: {
            width: "95%",
            padding: "10px 20px",
        },
        investorItem: {
            padding: "10px 0",
            width: "30%",
            "& img": {
                width: "100%",
            }
        },
    }
});

function InvestorSection({ data }) {
    const classes = useStyles();
    return (
        <BlockBox backgroundColor="#fff">
            <Typography variant="h2" className="center mb10">{data.title}</Typography>
            <Typography variant="h5" className="center bold-100 mb20">
                <ReactMarkdown source={data.subtitle} />
            </Typography>
            <div className={classes.investorList}>
                {
                    data.investor_list.map((brand, index) => (
                        <div
                            key={index}
                            className={classes.investorItem}
                        >
                            <img
                                src={brand.image}
                                alt={brand.title}
                            />
                        </div>
                    ))
                }
            </div>
        </BlockBox>
    )
}

export default InvestorSection;