import { Typography } from "@material-ui/core";
import Quote from "assets/web/quote.svg";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    container: {
        margin: '0 auto!important',
        backgroundColor: "#eff6fc",
        borderRadius: "17px",
        position: "relative",
        padding: 30,
        width: "300px",
        minHeight: "455px",
        height: "455px",
    },
    quote: {
        position: "absolute",
        left: 20,
        top: 20,
    },
    testimonialContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
    },
    reviewerContainer: {
        display: "flex",
        alignItems: "center",
        "& img": {
            marginRight: "10px",
        }
    },
    "@media screen and (max-width:760px)": {
        container: {
            width: "95%",
            minHeight: "400px",
            height: "450px",
        }
    }
})


function TestimonialCard({
    data,
}) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.quote}>
                <Quote />
            </div>
            <div className={classes.testimonialContainer}>
                <div>
                    {data.testimonial}
                </div>
                <div className={classes.reviewerContainer}>
                    <img
                        src={data.reviewer.image}
                        alt={data.reviewer.name}
                    />
                    <div>
                        <Typography>
                            {data.reviewer.name}
                        </Typography>
                        <Typography>
                            {data.reviewer.designation}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonialCard;