// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RightCaret from "assets/web/RightCaret.svg";
import Typography from "@material-ui/core/Typography";
import { createUseStyles } from 'react-jss';
import { SECONDARY_COLOR } from 'utils/data';
import clsx from 'clsx';
import { HTMLAttributes } from 'react';

const useStyles = createUseStyles({
    exploreButton: {
        display: "flex",
        alignItems: "center",
    },
    centerButton: {
        justifyContent: "center",
    },
    icon: {
        color: SECONDARY_COLOR,
        fontWeight: 700,
    },
    text: {

    },
})

function ExploreButton({
    text,
    url,
    buttonClassName,
    center,
    ...props
}: {
    text: string,
    url: string,
    buttonClassName?: any,
    center?: boolean,
    id?: string,
    onClick?: any,
}) {
    const classes = useStyles();

    const biggerIcon = text.split(" ").length > 2;

    const renderText = (text: string) => {
        const nText = text.split(" ");
        let component = null;

        if (nText.length <= 2) {
            return (
                <Typography
                    variant="h5"
                    color="secondary"
                >
                    {text}
                </Typography>
            )
        } else {
            return (
                nText.reduce((accumulator, currentValue, currentIndex, array) => {
                    if (currentIndex % 2 === 0) {
                        accumulator.push(array.slice(currentIndex, currentIndex + 2));
                    }
                    return accumulator;
                }, []).map(((el, index) => (
                    <Typography
                        key={index}
                        variant="h5"
                        color="secondary"
                    >
                        {el.join(" ")}
                    </Typography>
                )))
            )
        }
    }

    return (
        <a href={url} className="text-decoration-none" {...props}>
            <div className={clsx(
                classes.exploreButton,
                buttonClassName,
                center ? classes.centerButton : ""
            )}>
                <div className={classes.text}>
                    {renderText(text)}
                </div>
                <RightCaret
                    className={clsx("mui-icon", classes.icon)}
                    style={{
                        fontSize: biggerIcon ? "-webkit-xxx-large" : "1.5rem"
                    }}
                />
            </div>
        </a>
    )
}

export default ExploreButton;