import { Typography } from "@material-ui/core";
import Quote from "assets/web/quote.svg";
import { createUseStyles } from "react-jss";
import ReactMarkdown from "./ReactMarkdown";
import LinkedinColor from "assets/web/LinkedinColor.svg";
import { Stream } from "@cloudflare/stream-react";
import { handleVideoPlayEvent } from "utils/mixpanelEvents";
import YouTube from 'react-youtube';

const useStyles = createUseStyles({
    container: {
        margin: '0 auto!important',
        backgroundColor: "#d2e3f4",
        borderRadius: "17px",
        padding: "20px",
        width: "80%",
        // width: "300px",
        // minHeight: "455px",
        // height: "455px",
    },
    quote: {
        position: "absolute",
        left: 20,
        top: 20,
    },
    wrapper: {
        position: "relative",
        display: "flex",
    },
    testimonialContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        width: "80%",
        margin: "0 auto",
        textAlign: "center",
        "& p": {
            margin: 0,
        }
    },
    reviewerContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        textAlign: "center",
        width: "80%",
        margin: "20px auto",
        // marginBottom: "20px",
    },
    reviewerText: {
        width: "35%",
        margin: "0 auto",
    },
    videoSection: {
        // height: "300px",
        width: "50%",
    },
    videoContainer: {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        paddingTop: "56.25%",
        "& iframe": {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            // height: "320px!important",
            // width: "565px!important",
            border: "none",
            borderRadius: "10px",
        }
        // height: "220px",
        // width: "390px",
        // marginBottom: "20px",
        // "& iframe": {
        //     margin: "0 auto",
        //     height: "220px!important",
        //     width: "390px!important",
        //     borderRadius: "10px",
        // }
    },
    designation: {
        margin: "10px",
        "& p": {
            margin: 0,
        }

    },
    '@media screen and (max-width:760px)': {
        quote: {
            position: "absolute",
            left: 20,
            top: 20,
        },
        container: {
            width: "95%",
            // padding: "0",
            // display: "flex"
        },
        reviewerText: {
            width: "90%",
            margin: "0 auto",
        },
        testimonialContainer: {
            width: "95%",
            textAlign: "center",
        },
        reviewerContainer: {
            flexDirection: "column",
            width: "95%",
            margin: "15px auto",
        },
        videoSection: {
            width: "100%",
        },
        videoContainer: {
            // height: "190px",
            // width: "320px",
            // "& iframe": {
            //     margin: "0 auto",
            //     height: "180px!important",
            //     width: "320px!important",
            // }
        }
    }
})


function SingleTestimonialVideoCard({
    data,
    autoplay,
    setAutoPlay,
}) {
    const classes = useStyles();

    const opts: any = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div className={classes.container}>
            <div className={classes.reviewerContainer}>
                <div className={classes.videoSection}>
                    {/* <Stream
                        controls
                        className={classes.videoContainer}
                        src={data.reviewer.video.split("/")[data.reviewer.video.split("/").length - 1]}
                        onPlay={() => {
                            setAutoPlay(false);
                            handleVideoPlayEvent(`testimonial-${data.reviewer.name}`);
                        }}
                        onPause={() => setAutoPlay(true)}
                        poster={data.reviewer.thumbnail}
                        preload={false}
                    /> */}
                    <div
                        className={classes.videoContainer}
                    >
                        <YouTube
                            videoId={data.reviewer.video}
                            opts={opts}
                            onPlay={() => {
                                setAutoPlay(false);
                                handleVideoPlayEvent(`testimonial-${data.reviewer.name}`);
                            }}
                        // onReady={this._onReady}
                        />
                    </div>
                </div>
                <div className={classes.reviewerText}>
                    <Typography
                        variant="h5"
                    >
                        {data.reviewer.name}
                    </Typography>
                    <ReactMarkdown
                        className={classes.designation}
                        source={data.reviewer.designation}
                    />
                    {
                        data.reviewer.url ?
                            <div>
                                <a href={data.reviewer.url} target="_blank">
                                    <LinkedinColor
                                        className="mui-icon"
                                    />
                                </a>
                            </div>
                            : ""
                    }
                </div>
            </div>
            <div className={classes.wrapper}>
                <div className={classes.quote}>
                    <Quote />
                </div>
                <div className={classes.testimonialContainer}>
                    <ReactMarkdown
                        source={data.testimonial}
                    />
                </div>
            </div>
        </div >
    )
}

export default SingleTestimonialVideoCard;