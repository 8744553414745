import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { createUseStyles } from "react-jss";
import BlockBox from "components/generics/BlockBox";

const useStyles = createUseStyles({
    brandSection: {},
    brandList: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
    },
    brandItem: {
        margin: "20px 20px",
        "& img": {
            width: "120px",
        }
    },
    "@media screen and (max-width: 768px)": {
        brandSection: {},
        brandList: {},
        brandItem: {},
    }
})

function FeaturedInBrands({ data, backgroundColor = "transparent" }) {
    const classes = useStyles();

    return (
        <BlockBox
            slant={false}
            backgroundColor={backgroundColor}
        >
            <div className={clsx("mtb20", "max-width", classes.brandSection)}>
                <div className="center">
                    <Typography
                        variant="h2"
                    >
                        {data.title}
                    </Typography>

                    <div className={classes.brandList}>
                        {
                            data.feature_list.map((brand, index) => (
                                <div
                                    key={index}
                                    className={classes.brandItem}
                                >
                                    <img
                                        src={brand.image}
                                        alt={brand.title}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </BlockBox>
    )
}

export default FeaturedInBrands;