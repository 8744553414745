import clsx from "clsx";
import BlockBox from "components/generics/BlockBox";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    sectionLeft: {
        width: "40%",
        // textAlign: "justify",
        '& img': {
            width: '543px',
            height: 'auto',
        }
    },
    sectionRight: {
        width: "55%",
        display: "flex",
        justifyContent: "flex-end",
        '& img': {
            width: '543px',
            height: 'auto',
        }
    },
    '@media screen and (max-width:760px)': {
        container: {
            flexDirection: "column",
            padding: "0 10px",
        },
        sectionLeft: {
            width: "100%!important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            '& img': {
                width: '322px!important',
            }
        },
        sectionRight: {
            width: "100%",
            marginTop: "50px",
            justifyContent: "center",
            textAlign: "center",
            '& img': {
                width: '322px!important',
            }
        },
    }
})

function CardSection({
    slant = false,
    leftWidth,
    leftSection,
    rightSection,
    thirdSection = null,
    containerClassName = {},
    rightSectionClassName = {},
    leftSectionClassName = {},
    rightWidth = null,
    backgroundColor = "#fff",
}) {

    const classes = useStyles();
    return (
        <BlockBox
            slant={slant}
            backgroundColor={backgroundColor}
        >
            <div className="p10">
                <div className={clsx(classes.container, containerClassName)}>
                    <div
                        className={clsx(classes.sectionLeft, leftSectionClassName)}
                        style={{
                            width: `${leftWidth}%`
                        }}
                    >
                        {leftSection}
                    </div>
                    <div
                        className={clsx(classes.sectionRight, rightSectionClassName)}
                    >
                        {rightSection}
                    </div>
                </div>
                {
                    thirdSection
                        ?
                        <div
                            className={clsx(classes.sectionLeft, leftSectionClassName)}
                            style={{
                                width: `${leftWidth}%`
                            }}
                        >
                            {thirdSection}
                        </div>
                        : ""
                }
            </div>
        </BlockBox>
    )
}

export default CardSection;
