import { Typography } from "@material-ui/core";
import AlternateCardSections from "components/generics/AlternateCardSections";
import BlockBox from "components/generics/BlockBox";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    features: {
    },
    list: {
        margin: "50px 0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    listItem: {
        width: "20%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    featureImg: {
        width: "auto",
        height: "280px",
    },
    footNote: {
        textAlign: "center",
    },
    exploreButton: {
        justifyContent: "center",
    },
    "@media screen and (max-width:760px)": {
        features: {
            textAlign: "center",
        },
        list: {
            flexDirection: "column",
            textAlign: "center",
        },
        listItem: {
            width: "90%",

        },
        featureImg: {
            width: "280px",
            height: "auto",
        },
    }
})

function Features({
    data,
    cta = true,
    ctaComponent = null,
}) {
    const classes = useStyles();

    return (
        <BlockBox
            backgroundColor="#f8efda"
            slantSM={true}
        >
            <div className={classes.features}>
                <Typography
                    variant="h2"
                    className="center mt40"
                >

                    {data.title}
                </Typography>
                <AlternateCardSections
                    data={data.feature_list}
                    backgroundColor="#f8efda"
                />
                {/* <div className={classes.list}>
                    {
                        data.feature_list.map((item, index) => (
                            <div
                                key={index}
                                className={classes.listItem}
                            >
                                <img
                                    src={item.image}
                                    alt={item.title}
                                    className={`img ${classes.featureImg}`}
                                />

                                <Typography className="center" variant="h5">
                                    {item.title}
                                </Typography>
                                <ReactMarkdown
                                    source={item.description}
                                />
                            </div>
                        ))
                    }
                </div> */}
                {/* {
                    cta
                        ?
                        <ExploreButton
                            text={data.cta_text}
                            url={data.cta_url}
                            buttonClassName={classes.exploreButton}
                        />
                        : ""
                } */}
                {
                    ctaComponent ? ctaComponent : ""
                }
            </div>
        </BlockBox>
    )
}

export default Features;