import Layout from 'components/generics/Layout';
import { getHomeContent, getMakeHappyComponent } from './api';
import BlueFooter from 'components/styledComponents/BlueFooter';
import Testimonials from 'components/styledComponents/Testimonials';
import Header from 'components/home/Header';
import Features from 'components/home/Features';
import FAQContainer from 'components/generics/FAQContainer';
import SingleTestimonialVideoCard from 'components/generics/SingleTestimonialVideoCard';
import TeachersSection from 'components/home/TeachersSection';
import PriceSection from 'components/home/PriceSection';
import { SyntheticEvent, useState } from 'react';
import CoursesSection from 'components/home/CoursesSection';
import Typography from '@material-ui/core/Typography';
import Button from 'components/generics/Button';
import { handleApplyNowEvent } from 'utils/mixpanelEvents';
import { handleLinkClick, WHATSAPP_LINK_EVENT } from 'utils/events';
import config from 'config';
import FeaturedInBrands from 'components/press/FeaturedInBrands';
import InvestorSection from 'components/home/InvestorSection';

function Home({
  meta,
  schema,
  header,
  features,
  courseSection,
  curriculumSection,
  cardSections,
  testimonials,
  teachersSection,
  pricingSection,
  masterclassSection,
  featuredIn,
  investorSection,
  faqs,
  happyBoxData,
  ...props
}) {
  const [applyNowOpen, setApplyNowOpen] = useState(false);

  const handleSetApplyNowOpen = (e: SyntheticEvent) => {
    if (e) {
      setApplyNowOpen(true);
      handleApplyNowEvent(e.currentTarget.id);
    } else {
      setApplyNowOpen(false);
    }
  };

  return (
    <Layout
      meta={meta}
      schema={schema}
      applyNowOpen={applyNowOpen}
      handleSetApplyNowOpen={handleSetApplyNowOpen}
    >
      <Header data={header} handleSetApplyNowOpen={handleSetApplyNowOpen} />
      <Features
        data={features}
        ctaComponent={
          <div className="center">
            <Typography variant="body2">Need more Information?</Typography>
            <Button
              id="home-page-contact-us"
              variant="contained"
              color="secondary"
              onClick={(e) =>
                handleLinkClick(WHATSAPP_LINK_EVENT, e, config.WHATSAPP_LINK)
              }
            >
              Contact Us
            </Button>
          </div>
        }
      />

      {/* Removed the below section as discussed */}
      {/* <CoursesSection
        data={courseSection}
        handleSetApplyNowOpen={handleSetApplyNowOpen}
      /> */}

      {/* <CurriculumSection
                data={curriculumSection}
                setApplyNowOpen={setApplyNowOpen}
            /> */}
      <Testimonials
        testimonials={testimonials}
        CardComponent={SingleTestimonialVideoCard}
        slidesToShow={1}
        backgroundColor="#f7f8fa"
      />
      <TeachersSection data={teachersSection} />
      <PriceSection
        pricingData={pricingSection}
        masterclassData={masterclassSection}
        handleSetApplyNowOpen={handleSetApplyNowOpen}
      />
      <FeaturedInBrands data={featuredIn} backgroundColor="#fff" />
      <InvestorSection data={investorSection} />
      <FAQContainer faqs={faqs} />
      <BlueFooter data={happyBoxData} footer />
    </Layout>
  );
}

export async function getStaticProps() {
  const data: any = await getHomeContent();
  const happyBoxData: any = await getMakeHappyComponent();

  return {
    props: {
      meta: data.data.meta,
      schema: data.data.schema,
      header: data.data.header,
      features: data.data.features,
      courseSection: data.data.courses_section,
      curriculumSection: data.data.curriculum_section,
      teachersSection: data.data.teachers_section,
      pricingSection: data.data.pricing_section,
      testimonials: data.data.testimonials,
      masterclassSection: data.data.masterclass_section,
      featuredIn: data.data.featured_in,
      investorSection: data.data.investor_section,
      faqs: data.data.faqs,
      happyBoxData: happyBoxData.data
    }
  };
}

export default Home;
