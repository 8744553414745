import clsx from "clsx";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "20px 0",
    },
    cardContainer: {
        borderRadius: "17px",
        padding: "20px",
        background: "#fff",
    },
    cardTitle: {
        borderTopLeftRadius: "17px",
        borderTopRightRadius: "17px",
        padding: "20px",
        paddingTop: "15px",
        marginBottom: "-10px",
        textAlign: "center",
        fontSize: "18px",
        fontWeight: 500,
    },
    primary: {
        border: "1px solid rgba(255, 178, 0, 0.8)",
        boxShadow: "0px 4px 4px #FFC133",
    },
    primaryBackground: {
        background: "rgba(255, 178, 0, 0.5)",
    },
    secondary: {
        border: " 1px solid rgba(249, 117, 218, 0.8)",
        boxShadow: "0px 4px 4px #F975DA;",
    },
    secondaryBackground: {
        background: "rgba(249, 117, 218, 0.5)",
    },
    "@media screen and (max-width: 760px)": {
        cardTitle: {
            fontSize: "14px",
        }
    }
})

function Card({
    type,
    title,
    className,
    containerClassName,
    children,
}: {
    children: React.ReactNode,
    type: string
    className?: any,
    containerClassName?: any,
    title?: string
}) {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.card, className)}
        >
            {
                title
                    ?
                    <div
                        className={clsx(classes.cardTitle,
                            type === "primary" ? classes.primaryBackground : classes.secondaryBackground
                        )}
                    >
                        {title}
                    </div>
                    : ""
            }
            <div
                className={clsx(
                    classes.cardContainer,
                    containerClassName,
                    type === "primary" ? classes.primary : classes.secondary
                )}
            >
                {children}
            </div>

        </div>
    )
}

export default Card;