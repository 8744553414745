import { default as RMMarkdown } from "react-markdown";

// import directive from "utils/rm-directive";

import Strike from "./Strike";

function ReactMarkdown(props) {    
    const components = {
        Strike,
    }

    // const plugins = [
    //     [directive, { components }]
    // ]

    return (
        <RMMarkdown
            {...props}
            // plugins={plugins}
        />
    )
}

export default ReactMarkdown;