import { useEffect } from 'react';
import { useRef } from 'react';
import YouTube from 'react-youtube';

interface YouTubePlayerProps {
  youtubeID: string;
  autoPlay?: boolean;
  optionalOpts?: {
    [key: string]: string;
  };
}

function YouTubePlayer({ youtubeID, autoPlay, optionalOpts={} }: YouTubePlayerProps) {
  const youtubeRef = useRef(null);

  useEffect(() => {
    const { current } = youtubeRef;
    if (current) {
      current.addEventListener('onReady', handlePlayerReady);
    }
  }, []);

  const handlePlayerReady = (e) => {};

  // const opts = {
  //     height: '390',
  //     width: '640',
  //     playerVars: {
  //       // https://developers.google.com/youtube/player_parameters
  //       autoplay: 1,
  //     },
  //   };

  const width = typeof window !== 'undefined' && window.screen.width;
  const smallerScreen = width < 760;

  const opts: any = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: autoPlay === undefined ? (smallerScreen ? 0 : 1) : autoPlay,
      mute: autoPlay === undefined ? (smallerScreen ? 0 : 1) : 0
    },
    ...optionalOpts
  };

  return (
    <div className="yt-lite">
      <YouTube videoId={youtubeID} opts={opts} />
      {/* <iframe
                ref={youtubeRef}
                // class=""
                title="StayQrious"
                // width="560"
                // height="315"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                src={`https://www.youtube-nocookie.com/embed/${youtubeID}?autoplay=1&mute=1&playsinline=1`}
            // onReady={handlePlayerReady}
            >
            </iframe> */}
    </div>
  );
}

export default YouTubePlayer;
