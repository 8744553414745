import { Typography } from "@material-ui/core";
import clsx from "clsx";
import BlockBox from "components/generics/BlockBox";
import Card from "components/generics/Card";
import EnrolButton from "components/landing/EnrolButton";
import { createUseStyles } from "react-jss";
import ReactMarkdown from "react-markdown";
import { handleLinkClick, MASTERCLASS_CLICK_EVENT } from "utils/events";

const useStyles = createUseStyles({
    pricingSection: {
        textAlign: "center",
        width: "90%",
        margin: "20px auto",
    },
    button: {

    },
    masterclassSection: {
        width: "90%",
        margin: "0 auto",
        textAlign: "center"
    },
    masterButton: {
        color: "#fff!important",
    },
    masterclassFootNote: {
        width: "80%",
        margin: "0 auto!important"
    },
    pricingTitle: {
        marginBottom: "10px!important",
    },
    priceBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& h3": {
            fontWeight: "normal",
        }
    },
    masterclassDescription: {
        fontSize: "24px!important",
        marginTop: "20px!important",
    },
    refundText: {
    },
    image: {

    },
    list: {
        display: "flex",
        width: "90%",
        justifyContent: "space-between",
        margin: "20px auto",
    },
    listItem: {
        width: "30%",
    },
    description: {
        "& p": {
            fontSize: "16px",
            margin: "5px 0",
        }
    },
    offerDialog: {
        display: "flex",
        flexDirection: "column-reverse",
        marginTop: "20px",
    },
    specialOffer: {
        background: "linear-gradient(82.11deg, #FF7200 -17.26%, #FF8C00 -6.77%, #FFA700 6.67%, #FFBB00 21.45%, #FFCA00 38.06%, #FFD200 57.98%, #FFD500 90.09%)",
        width: "max-content",
        margin: "0 auto",
        padding: "10px 20px",
        borderRadius: "10px",
        marginBottom: "-20px",
        zIndex: 99
    },
    specialOfferTitle: {
        fontWeight: "100!important",
    },
    offer: {
        // marginTop: "-10px",
        background: "#def7fe",
        width: "60%",
        margin: "0 auto",
        padding: "20px",
        zIndex: 9,
    },
    footNote: {},
    featureContainerCard: {
        width: "80%",
        margin: "20px auto",
        "& img": {
            width: "70px!important"
        },
    },
    featureContainer: {
    },
    featureTitle: {},
    featureCardContainer: {},
    featureListTitle: {
        minHeight: "48px",
    },
    featureListDescription: {},
    "@media screen and (max-width:760px)": {
        pricingSection: {
            width: "100%",
        },
        pricingTitle: {
            fontSize: "14px!important",
        },
        refundText: {
            fontSize: "12px!important"
        },
        masterclassSection: {
            width: "95%",
        },
        masterclassFootNote: {
            fontWeight: 400,
        },
        masterclassDescription: {
            fontSize: "14px!important"
        },
        image: {
            maxWidth: "100%",
            display: "block",
            height: "auto",
            margin: "0 auto",
        },
        list: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            margin: "0px auto",
            padding: 0,
        },
        listItem: {
            width: "29%",
            margin: "0 auto",
        },
        specialOfferTitle: {
            fontSize: "14px!important",
        },
        offer: {
            width: "95%",
            padding: "0 5px",
            paddingTop: "10px",
            paddingBottom: "15px",
        },
        specialOffer: {
            marginBottom: "-15px"
        },
        featureContainerCard: {
            width: "100%",
        },
        featureCardContainer: {
            padding: "5px",
        },
        featureContainer: {
            margin: "10px 0",
        },
        featureTitle: {
            marginBottom: "20px!important",
        },
        featureListTitle: {
            fontSize: "11px!important",
            minHeight: "28px",
            lineHeight: "14px!important",
        },
        featureListDescription: {
            "& p": {
                fontSize: "10px!important",
                lineHeight: "14px!important",
            }
        },
    }
})

function PriceSection({
    pricingData,
    masterclassData,
    handleSetApplyNowOpen,
}) {
    const classes = useStyles();

    const renderPrice = (price: string) => {
        const priceStmt = price.split("/");
        const sub = priceStmt[1].split(" ");

        return (
            <div className={classes.priceBox}>
                <Typography
                    variant="h1"
                    color="secondary"
                >
                    {priceStmt[0] + "/ "}
                </Typography>
                <Typography
                    variant="h3"
                    color="secondary"
                >
                    {sub[0]}
                </Typography>
                <Typography
                    variant="h4"
                    color="secondary"
                    className="bold-500"
                >
                    &nbsp;{`${sub[1]}`}
                </Typography>
            </div>
        )
    }

    return (
        <>
            <BlockBox
                slant={false}
                backgroundColor="#f9f3e2"
            >
                <a className="pricing-anchor" id="pricing"></a>
                <div
                    className={classes.pricingSection}
                >
                    <Typography
                        variant="h2"
                    >
                        {pricingData.title}
                    </Typography>
                    <Card
                        type="secondary"
                        className={classes.featureContainerCard}
                        containerClassName={classes.featureCardContainer}
                    >
                        {
                            pricingData.pricing_features.map((features, index) => (
                                <div key={index} className={classes.featureContainer}>
                                    <Typography
                                        variant="h4"
                                        className={classes.featureTitle}
                                    >
                                        {features.title}
                                    </Typography>
                                    <div className={classes.list}>
                                        {
                                            features.feature_list.map((item, index) => (
                                                <div key={index} className={classes.listItem}>
                                                    <img
                                                        src={item.image}
                                                        alt="SQ"
                                                        className="image"
                                                    />
                                                    <Typography
                                                        variant="body1"
                                                        className={clsx("bold-500", classes.featureListTitle)}>
                                                        {item.title}
                                                    </Typography>
                                                    <ReactMarkdown
                                                        className={clsx(classes.description, classes.featureListDescription)}
                                                        source={item.description}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </Card>

                    {/*renderPrice(pricingData.price)*/}
                    <EnrolButton
                        id="apply-now-pricing-special-offer"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={clsx("mt10 bold-500")}
                        // link={pricingData.special_offer.cta_url}
                        onClick={(e) => handleSetApplyNowOpen(e)}
                    >
                        {pricingData.special_offer.cta_text}
                    </EnrolButton>
                    {/* <div className={classes.offerDialog}>
                        <div className={classes.offer}>
                            <Typography
                                variant="body1"
                                className="mt10"
                            >
                                {pricingData.special_offer.subtitle}
                            </Typography>
                            <Typography
                                variant="h2"
                            >
                                {pricingData.special_offer.discounted_price}
                            </Typography>
                            <EnrolButton
                                id="apply-now-pricing-special-offer"
                                variant="contained"
                                color="primary"
                                size="small"
                                className={clsx("mt10 bold-500")}
                                // link={pricingData.special_offer.cta_url}
                                onClick={(e) => handleSetApplyNowOpen(e)}
                            >
                                {pricingData.special_offer.cta_text}
                            </EnrolButton>
                        </div>
                        <div className={classes.specialOffer}>
                            <Typography
                                variant="h3"
                                className={classes.specialOfferTitle}
                            >
                                {pricingData.special_offer.title}
                            </Typography>
                        </div>
                    </div> */}

                    <Typography
                        variant="body1"
                        className={clsx("bold mt10")}
                    >
                        {pricingData.more_info}
                    </Typography>
                    <a href={pricingData.enquiry_cta}>
                        <Typography
                            variant="body1"
                            color="secondary"
                            className={clsx()}
                        >
                            {pricingData.enquiry_title}
                        </Typography>
                    </a>
                </div>
            </BlockBox>
            <BlockBox
                slant={false}
                backgroundColor="#eff6fc"
            >
                <Typography variant="h3" className={clsx("center", classes.pricingTitle)}>
                    <b>{masterclassData.title}</b>
                </Typography>
                <div className={classes.masterclassSection}>
                    <img
                        src={masterclassData.image}
                        alt="SQ"
                        className={classes.image}
                    />
                    <Typography variant="body1" className={classes.masterclassDescription}>
                        {masterclassData.description}
                    </Typography>
                    <EnrolButton
                        id="masterclass-section"
                        variant="contained"
                        color="secondary"
                        className={clsx("mtb20", classes.masterButton)}
                        link={masterclassData.cta_url}
                        onClick={(e) => handleLinkClick(MASTERCLASS_CLICK_EVENT, e, masterclassData.cta_url)}
                        external
                    >
                        {masterclassData.cta_text}
                    </EnrolButton>
                    <Typography
                        variant="body2"
                        className={classes.masterclassFootNote}
                    >
                        {masterclassData.foot_note}
                    </Typography>
                </div>
            </BlockBox>
        </>
    )
}

export default PriceSection;
